import { graphql } from 'gatsby';
import React from 'react';
import Callout from '../components/Callout';
import { Column, Container, Row } from '../components/Grid';
import Hero from '../components/Hero';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Quicklinks from '../components/Quicklinks';
import Quote from '../components/Quote';
import Resource from '../components/Resource';
import Section from '../components/Section';
import { getKeyedResources } from '../utils/resource-helper';

const ActivitiesPage = ({ data }) => {
  const {
    backyardLights,
    countryMusicEntrepreneurs,
    familySuperstar,
    lightItUp,
    soundsOfSteamCountry,
    awardCeremony,
    makesAClassic,
    mathBehindMusic,
    windChimes,
    remakesAndRemixes,
    makingWaves,
    rhythmAndRatios,
    goodVibrations,
  } = getKeyedResources(data);

  return (
    <Layout title="Activities">
      <Hero
        mediaSource="activities-hero.jpg"
        textOverlay="Activities"
        className="mb-0"
      />
      <Section noPadding>
        <Container fullWidth className="activities-intro__container">
          <Quicklinks
            className="activities-intro__container__quicklinks"
            links={[
              {
                target: '#vts2-activities',
                label: 'Anatomy of a Hit Song Activities',
              },
              {
                target: '#vts1-activities',
                label: 'STEAM Country Activities',
              },
              {
                target: '#vft-activities',
                label: 'Virtual Field Trip Activities',
              },
              {
                target: '#family-activities',
                label: 'Family Activities',
              },
            ]}
          />
          <div className="activities-intro__container__intro-text">
            <h1 className="uppercase">
              Bring Country Music to Life in Class or at Home
            </h1>
            <p className="large">
              Discover how STEAM skills are used to create the unique sounds and
              unforgettable experiences of Country Music with these hands-on
              activities. Ignite collaboration in any environment with flexible
              resources that combine creative expression with STEAM problem
              solving.
            </p>
          </div>
          <div className="activities-intro__container__image-div">
            <Image
              className="activities-intro__container__image-div__banjo-image"
              filename="activities-graphic-banjo.svg"
            />
          </div>
          <div
            id="vts2-activities"
            className="activities-intro__container__classroom-activities-text"
          >
            <h2>Anatomy of a Hit Song Activities</h2>
            <p className="small">
              Take students behind the scenes of Country Music production to
              learn what goes into the making of a hit song. Use these
              standards-aligned classroom activities, discussions, and career
              connections to bring lessons to life in the classroom.
            </p>
          </div>
        </Container>
        <Container fullWidth className="activities-classroom__container">
          <Row>
            <Column size={4}>
              <Resource
                tophat={remakesAndRemixes.tophat}
                title={remakesAndRemixes.title}
                audience={remakesAndRemixes.audience}
                duration={remakesAndRemixes.duration}
                className={remakesAndRemixes.className}
                subtitle={remakesAndRemixes.subtitle}
                description={remakesAndRemixes.description}
                img={remakesAndRemixes.img}
                actions={remakesAndRemixes.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={makingWaves.tophat}
                title={makingWaves.title}
                audience={makingWaves.audience}
                duration={makingWaves.duration}
                className={makingWaves.className}
                subtitle={makingWaves.subtitle}
                description={makingWaves.description}
                img={makingWaves.img}
                actions={makingWaves.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={rhythmAndRatios.tophat}
                title={rhythmAndRatios.title}
                audience={rhythmAndRatios.audience}
                duration={rhythmAndRatios.duration}
                className={rhythmAndRatios.className}
                subtitle={rhythmAndRatios.subtitle}
                description={rhythmAndRatios.description}
                img={rhythmAndRatios.img}
                actions={rhythmAndRatios.actions}
              />
            </Column>
          </Row>
        </Container>
        {/* VTS ONE */}
        <Container fullWidth className="activities-family__container">
          <div
            id="vts1-activities"
            className="activities-family__container__text"
          >
            <h2>STEAM Country Activities</h2>
            <p className="small">
              Find out how diverse Country Music careers harness essential STEAM
              skills with these standards-aligned activities. Bring the magic of
              Country Music into the classroom with activities aligned to the
              This Is STEAM Country Video Topic Series.
            </p>
          </div>
          <Row>
            <Column size={4}>
              <Resource
                tophat={soundsOfSteamCountry.tophat}
                title={soundsOfSteamCountry.title}
                audience={soundsOfSteamCountry.audience}
                duration={soundsOfSteamCountry.duration}
                className={soundsOfSteamCountry.className}
                subtitle={soundsOfSteamCountry.subtitle}
                description={soundsOfSteamCountry.description}
                img={soundsOfSteamCountry.img}
                actions={soundsOfSteamCountry.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={lightItUp.tophat}
                title={lightItUp.title}
                audience={lightItUp.audience}
                duration={lightItUp.duration}
                className={lightItUp.className}
                subtitle={lightItUp.subtitle}
                description={lightItUp.description}
                img={lightItUp.img}
                actions={lightItUp.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={countryMusicEntrepreneurs.tophat}
                title={countryMusicEntrepreneurs.title}
                audience={countryMusicEntrepreneurs.audience}
                duration={countryMusicEntrepreneurs.duration}
                className={countryMusicEntrepreneurs.className}
                subtitle={countryMusicEntrepreneurs.subtitle}
                description={countryMusicEntrepreneurs.description}
                img={countryMusicEntrepreneurs.img}
                actions={countryMusicEntrepreneurs.actions}
              />
            </Column>
          </Row>
        </Container>
        {/* VIRTUAL FIELD TRIP */}
        <Container fullWidth className="activities-family__container">
          <div
            id="vft-activities"
            className="activities-family__container__text"
          >
            <h2>Virtual Field Trip Activities</h2>
            <p className="small">
              Bring to life the excitement, talent, and teamwork of Country
              Music with activities that take students behind-the-scenes of the
              CMA Awards. Students will creatively problem-solve with a series
              of activities aligned to the STEAM Careers Take the Spotlight
              Virtual Field Trip: Country Music's Biggest Night&trade;.
            </p>
          </div>
          <Row>
            <Column size={4}>
              <Resource
                tophat={awardCeremony.tophat}
                title={awardCeremony.title}
                audience={awardCeremony.audience}
                duration={awardCeremony.duration}
                className={awardCeremony.className}
                subtitle={awardCeremony.subtitle}
                description={awardCeremony.description}
                img={awardCeremony.img}
                actions={awardCeremony.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={makesAClassic.tophat}
                title={makesAClassic.title}
                audience={makesAClassic.audience}
                duration={makesAClassic.duration}
                className={makesAClassic.className}
                subtitle={makesAClassic.subtitle}
                description={makesAClassic.description}
                img={makesAClassic.img}
                actions={makesAClassic.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={mathBehindMusic.tophat}
                title={mathBehindMusic.title}
                audience={mathBehindMusic.audience}
                duration={mathBehindMusic.duration}
                className={mathBehindMusic.className}
                subtitle={mathBehindMusic.subtitle}
                description={mathBehindMusic.description}
                img={mathBehindMusic.img}
                actions={mathBehindMusic.actions}
              />
            </Column>
          </Row>
        </Container>

        {/* FAMILY ACTIVITIES */}
        <Container fullWidth className="activities-family__container">
          <div
            id="family-activities"
            className="activities-family__container__text"
          >
            <h2>Family Activities</h2>
            <p className="small">
              Inspire collaborative learning experiences outside the classroom
              with activities that transform students’ home into a Country Music
              stage. Celebrate the power of STEAM with easy-to-use activities
              designed to bring the whole family together.
            </p>
          </div>
          <Row>
            <Column size={4}>
              <Resource
                ribbon="activities-badge-new.png"
                tophat={goodVibrations.tophat}
                title={goodVibrations.title}
                audience={goodVibrations.audience}
                duration={goodVibrations.duration}
                className={goodVibrations.className}
                subtitle={goodVibrations.subtitle}
                description={goodVibrations.description}
                img={goodVibrations.img}
                actions={goodVibrations.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={windChimes.tophat}
                title={windChimes.title}
                audience={windChimes.audience}
                duration={windChimes.duration}
                className={windChimes.className}
                subtitle={windChimes.subtitle}
                description={windChimes.description}
                img={windChimes.img}
                actions={windChimes.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={familySuperstar.tophat}
                title={familySuperstar.title}
                audience={familySuperstar.audience}
                duration={familySuperstar.duration}
                className={familySuperstar.className}
                subtitle={familySuperstar.subtitle}
                description={familySuperstar.description}
                img={familySuperstar.img}
                actions={familySuperstar.actions}
              />
            </Column>
          </Row>
          <Row>
            <Column size={4}>
              <Resource
                tophat={backyardLights.tophat}
                title={backyardLights.title}
                audience={backyardLights.audience}
                duration={backyardLights.duration}
                className={backyardLights.className}
                subtitle={backyardLights.subtitle}
                description={backyardLights.description}
                img={backyardLights.img}
                actions={backyardLights.actions}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Quote
        text="Music is  an opportunity to share cultures, to share backgrounds, to share stories."
        source="Alec Takahashi, Stage and Lighting Director for Thomas Rhett"
        backgroundImage="activities-block-quote.png"
        className="bg-white"
      />
    </Layout>
  );
};

export const query = graphql`
  query Activities {
    allActivitiesJson(
      filter: {
        pages: {
          in: ["classroom-activities", "family-activities", "vft-activities"]
        }
      }
    ) {
      edges {
        node {
          slug
          tophat
          img
          icon
          title
          audience
          duration
          description
          actions {
            label
            metadata
            module
            moduleName
            link
            download
            noIcon
            asLink
          }
          className
          pages
        }
      }
    }
  }
`;

export default ActivitiesPage;
